<template>
    <Header />

    <main>
        <router-view />
    </main>
    
    <Footer />
</template>

<script>
import Header from './components/header/Header.vue';
import Footer from './components/footer/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>