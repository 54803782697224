<template>
    <article class="latest__blogpost container" v-for="(item, index) in blogpostData" :key="index">
      <div class="latest__blogpost__slider">
        <div class="latest__blogpost__slider__img">
          <img :src="loadImage || item.relatedImages[0].url" :alt="loadAlt || item.relatedImages[0].description" />
        </div>

        <div class="latest__blogpost__slider__nav">
          <template v-for="(picture, index) in item.relatedImages.slice(0, 5)" :key="index">
            <div @click="activeImg(picture)" class="latest__blogpost__slider__nav__link">
              <img :src="picture.url" :alt="picture.description" />
            </div>
          </template>
        </div>
      </div>

      <div class="latest__blogpost__text">
        <h2>{{ item.title }}</h2>

        <div class="latest__blogpost__text__content" v-html="item.content.substring(0,500)+'...'"></div>
        
        <div class="latest__blogpost__text__readmore">
          <a :href="`/blog/${item.url}`">Lees meer...</a>
        </div>
      </div>
    </article>
  
</template>

<script>
import { computed, ref } from 'vue'
import { Client } from "../../client"
import { SortOrder } from '@kentico/kontent-delivery'

import './blogWidget.scss'

export default {
  name: 'blog-widget',
  data() {
    return {
      loadImage: "",
      loadAlt: ""
    }
  },
  setup() {
    const blogpost = ref([])

    Client.items()
      .type('blogpost')
      .orderParameter('elements.date', SortOrder.desc)
      .limitParameter(1)
      .toObservable()
      .subscribe(response => blogpost.value = response.items)

    const blogpostData = computed(() => blogpost.value.map(blogpost => ({ 
      title: blogpost.title.value,
      summary: blogpost.summary.value,
      content: blogpost.content.value,
      relatedImages: blogpost.related_images.value,
      url: blogpost.slug.value
    })))

    return {
      blogpostData
    }
  },
  methods: {
    activeImg(item) {
      this.loadImage = item.url,
      this.loadAlt = item.description
    }
  }
}
</script>