<template>
  <div class="form__field" :class="['__' + name, { 'has-error': !!errorMessage, success: meta.valid }]">
    <label :for="name" class="sr-only">{{ label }}</label>
    <textarea :id="name" :name="name" :value="inputValue" @input="handleChange" @blur="handleBlur" class="form__input" :placeholder="placeholder + '...'" :onfocus="`if (this.placeholder == '${placeholder}...') {this.placeholder = '';}`" :onblur="`if (this.placeholder == '') {this.placeholder = '${placeholder}...';}`" :disabled="disable"></textarea>
    <div v-show="errorMessage || meta.valid" class="form__message">{{ errorMessage || successMessage }}</div>
  </div> 
</template>

<script>
import { useField } from "vee-validate";

import './forms.scss'

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String
    },  
    disable: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style  scoped>

input {
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error input:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success input {
  background-color: var(--success-bg-color);
  color: var(--success-color);
}

.TextInput.success input:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>