<template>
    <header class="header" :class="[ isHome ? '--transparent' : '' ]">
        <router-link :to="{ name: 'Home' }" class="logo --basecolor --thin">
            <span class="logo__addition handwriting --white">Die</span> Fotochick
            <span class="sr-only">Logo, klik om naar de homepage te gaan</span>
        </router-link>

        <button type="button" @click="toggle" class="hamburger --pink mobile" :class="[ isOpen ? 'hamburger--opened' : 'hamburger--closed' ]">
            <span class="hamburger__bar"></span>
            <span class="hamburger__bar"></span>
            <span class="hamburger__bar"></span>
            <span class="sr-only">Open menu</span>
        </button>

        <div class="menu" :class="[ isOpen ? 'menu--opened' : 'menu--closed' ]">
            <router-link @click="toggle" :to="{ name: 'Home' }" class="menu__logo --basecolor --thin mobile">
                <span class="menu__logo__addition handwriting --black">Die</span> Fotochick
                <span class="sr-only">Logo, klik om naar de homepage te gaan</span>
            </router-link>

            <nav class="menu__nav">
                <ul class="menu__list">
                    <li class="menu__item"><router-link @click="toggle" :to="{ name: 'Home' }">Home</router-link></li>
                    <li class="menu__item"><router-link @click="toggle" :to="{ name: 'About' }">About</router-link></li>
                    <li class="menu__item"><router-link @click="toggle" :to="{ name: 'Portfolio' }">Portfolio</router-link></li>
                    <li class="menu__item"><router-link @click="toggle" :to="{ name: 'Blog' }">Blog</router-link></li>
                    <li class="menu__item"><router-link @click="toggle" :to="{ name: 'Contact' }">Contact</router-link></li>
                </ul>
            </nav>

            <ul class="socials mobile">
                <li class="socials__link facebook">
                    <a href="https://www.facebook.com/diefotochick" target="_blank">
                        <i class="fa fa-facebook"></i>
                        <span class="sr-only">Facebook</span>
                    </a>
                </li>
                
                <li class="socials__link instagram">
                    <a href="https://www.instagram.com/diefotochick" target="_blank">
                        <i class="fa fa-instagram"></i>
                        <span class="sr-only">Instagram</span>
                    </a>
                </li>
                
                <li class="socials__link twitter">
                    <a href="https://www.twitter.com/diefotochick" target="_blank">
                        <i class="fa fa-twitter"></i>
                        <span class="sr-only">Twitter</span>
                    </a>			
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import './header.scss'

export default {
  data() {
    return {
      isOpen: false
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const current = ref(router.currentRoute)
    const isHome = ref()

    function fetchRoute() {
      current.value.path === "/" ? isHome.value = true : isHome.value = false
    }

    watch(current, fetchRoute)

    return {
      isHome
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
