<template>
  <div class="not-found">
    <h1>404</h1>
    <p>Deze pagina kan niet worden weergegeven</p>
    <button @click="goBack">Ga terug</button>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>