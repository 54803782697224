<template>
  <Agenda />

  <section id="blog" class="widget__blog">
    <BlogWidget />
  </section>

  <section id="portfolio" class="widget__portfolio">
    <h2 class="heading__main">Beste Foto&apos;s</h2>

    <div class="container">
      <template v-for="(item, index) in homePortfolio" :key="index">
      <Gallery :galleryInput="item.images" :maxItems="12" :imgSrc="'url'" :imgAlt="'description'" />
      </template>
    </div>
  </section>
</template>

<script>
import { computed, ref} from 'vue'
import { Client } from "@/client"

import Agenda from '@/components/agenda/Agenda.vue'
import BlogWidget from '@/components/blogWidget/BlogWidget.vue'
import Gallery from '@/components/gallery/Gallery.vue'

import './home.scss'

export default {
  name: 'Home',
  components: {
    Agenda,
    BlogWidget,
    Gallery
  },
    setup() {
    const gallery = ref([])

    Client.items()
      .type('top_12_gallery') 
      .toObservable()
      .subscribe(response => gallery.value = response.items)

    const homePortfolio = computed(() => gallery.value.map(item => ({ 
      //image: item.photo.value[0].url,
      //alt: item.photo.value[0].description,
      images: item.images.value,
    })))

    return {
      homePortfolio
    }
  },
}
</script>
