<template>
  <div class="form__field" :class="['__' + name, { 'has-error': !!errorMessage, success: meta.valid }]">
    <label :for="name" class="sr-only">{{ label }}</label>
    <input :id="name" :name="name" :type="type" :value="inputValue" @input="handleChange" @blur="handleBlur" class="form__input" :placeholder="placeholder + '...'" :onfocus="`if (this.placeholder == '${placeholder}...') {this.placeholder = '';}`" :onblur="`if (this.placeholder == '') {this.placeholder = '${placeholder}...';}`" :disabled="disable" />
    <div v-show="errorMessage || meta.valid" class="form__message">{{ errorMessage || successMessage }}</div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

import './forms.scss'
import { string } from 'yup/lib/locale';

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>