<template>
  <div class="gallery">
    <template v-for="(item, index) in galleryInput.slice(0, maxItems)" :key="index">
      <div @click="openModal(item)" class="gallery__item">
        <span class="gallery__item__cover"><i class="gallery__item__cover__icon fa fa-plus"></i></span>
        <img class="gallery__item__image" :src="item[imgSrc]" :alt="item[imgAlt]" />
      </div>
    </template>
  </div>

  <Modal v-if="showModal" @close="showModal = false">
    <img class="gallery__img__large" :src="loadImage" :alt="loadAlt" />
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal"

import './gallery.scss'

export default {
  name: 'gallery',
  components: {
    Modal
  },
  props: {
    galleryInput: {
      type: Array,
      required: true,
    },
    maxItems: {
      type: Number,
      default: 1000,
    },
    imgSrc: {
      type: String,
      default: 'image'
    },
    imgAlt: {
      type: String,
      default: 'alt'
    }
  },
  data() {
    return {
      showModal: false,
      loadImage: "",
      loadAlt: ""
    }
  },
  methods: {
    openModal(item) {
      this.showModal = true,
      this.loadImage = item[this.imgSrc],
      this.loadAlt = item[this.imgAlt]
    }
  }
}
</script>