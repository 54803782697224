<template>
  <footer>
    <div class="footer__main container">
      <div class="footer__main__left">
        <h4>Contact</h4>
		    <h2>Vragen?</h2>

		    <p>Stel ze gerust! Ik doe mijn best je bericht zo snel mogelijk te beantwoorden.</p>
      </div>

      <div class="footer__main__right">
        <Form v-if="!state.isSubmitted" id="footerContact" class="footer__form" :validation-schema="schema" @submit="onSubmit">
		      <div v-if="state.recaptcha.length" class="footer__form__messages">{{ state.recaptcha }}</div>

		      <div class="footer__form__fields">
            <Input
              name="name"
              type="text"
              label="Naam"
              placeholder="Naam"
              success-message="Aangenaam!"
              :disable="state.isContactPage"
            />

            <Input
              name="email"
              type="email"
              label="Email"
              placeholder="Email"
              success-message="Geen typefouten?"
              :disable="state.isContactPage"
            />     

            <Textarea 
              name="message"
              label="Bericht"
              placeholder="Bericht"
              success-message=""
              :disable="state.isContactPage"
            />   
            
            <div class="form__field __captcha">
              <div v-if="!state.isContactPage" class="g-recaptcha footer__form__recaptcha " data-sitekey="6LeXAQMgAAAAACYIPD_E5RI4-5y1QC8047kbXXS6" data-theme="dark"></div>
            </div>	   

            <button class="footer__form__button" type="submit" :disabled="state.isContactPage">Versturen</button>        
          </div>
        </Form>

        <div v-else class="footer__confirmation">
          <p>Bedankt voor je bericht!</p>
        </div>
      </div>
    </div>

    <div class="footer__social">
      <a class="footer__social__link --facebook" href="https://www.facebook.com/diefotochick" target="_blank">
        <i class="fa fa-facebook"></i>
        <span class="sr-only">Facebook</span>
      </a>

      <a class="footer__social__link --instagram" href="https://www.instagram.com/diefotochick" target="_blank">
        <i class="fa fa-instagram"></i>
        <span class="sr-only">Instagram</span>
      </a>

      <a class="footer__social__link --twitter" href="https://www.twitter.com/diefotochick" target="_blank">
        <i class="fa fa-twitter"></i>
        <span class="sr-only">Twitter</span>
      </a>	
    </div>
  </footer>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Form } from "vee-validate";
import * as Yup from "yup";
import { setLocale } from 'yup';
import emailjs from 'emailjs-com';

import Input from "@/components/forms/Input.vue";
import Textarea from "@/components/forms/Textarea.vue";

import './footer.scss'

export default {
  name: 'Footer',
  components: {
    Input,
    Textarea,
    Form,
  },
  setup() {
    const state = reactive({
      isContactPage: true,
      isSubmitted: false,
      recaptcha: ''
    })

    //Disable Footer contact form on Contact page
    const router = useRouter()
    const route = useRoute()

    const current = ref(router.currentRoute)

    function fetchRoute() {
      current.value.path === "/contact" ? state.isContactPage = true : state.isContactPage = false
    }

    watch(current, fetchRoute)
    

    // Footer form logic
    setLocale({
      mixed: {
        required: 'Dit veld is verplicht',
      },
      string: {
        email: 'Vul een correct emailadres in',
        min: 'Gebruik minimaal ${min} tekens',
      }
    });

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const schema = Yup.object().shape({
      field: Yup.string().max(0, 'Laat dit veld leeg'),
      name: Yup.string().min(5).matches('^[ a-z/A-Z-]*$', 'Gebruik alleen letters en spaties').required(),
      email: Yup.string().email().required(),
      message: Yup.string().required(),
    });

    function onSubmit() {
      emailjs.sendForm('service_fotochick', 'template_contactFooter', 'footerContact', 'user_D24C9s8kFVIsIOpOHhZKu').then(() => {
          state.isSubmitted = true;
        }, () => {
          state.recaptcha = 'Vink nog even aan dat je geen robot bent!';
      })
    }

    return {
      state,
      schema,
      onSubmit
    }
  }
}
</script>