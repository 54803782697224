<template>
  <teleport to="body">
    <div class="modal__container" @click.self="$emit('close')">
      <div class="modal">
          <button class="close__button" @click="$emit('close')">
              <span class="close__button__bar __left"></span>
              <span class="close__button__bar __right"></span>
              <span class="sr-only">Sluit popup</span>
          </button>

          <div class="modal__content">
              <slot></slot>
          </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import './modal.scss'

export default {
  name: "Modal"
}
</script>